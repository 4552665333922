import React from "react"
import { Link } from "gatsby"
import Container from "../components/container.js"

export default function FourOFour() {
    return (
        <Container>
            <Link to="/" style={{textDecoration: "Underline"}}>Back to Home.</Link>
            <h1 className='title'>Woops! The page you tried to access does not exist.</h1>
        </Container>
    )
}